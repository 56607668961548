.statistica-max-width {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.statistica-container1 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.statistica-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.statistica-container3 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.statistica-container4 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
}
.statistica-container5 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.statistica-container6 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
}
@media(max-width: 991px) {
  .statistica-container2 {
    flex-wrap: wrap;
  }
  .statistica-container5 {
    align-self: flex-start;
  }
  .statistica-container6 {
    align-self: flex-start;
  }
}
@media(max-width: 767px) {
  .statistica-title {
    text-align: center;
  }
  .statistica-container2 {
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
  }
  .statistica-container3 {
    align-self: flex-start;
  }
  .statistica-text1 {
    text-align: center;
  }
  .statistica-container4 {
    flex: 1;
  }
  .statistica-text3 {
    text-align: center;
  }
  .statistica-text5 {
    text-align: center;
  }
  .statistica-text7 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .statistica-title {
    align-self: center;
    text-align: center;
  }
  .statistica-container2 {
    flex-wrap: wrap;
  }
  .statistica-container4 {
    align-self: flex-start;
  }
  .statistica-container5 {
    align-self: flex-start;
  }
  .statistica-container6 {
    align-self: flex-start;
  }
}
