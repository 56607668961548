.commercial-cleaning-1-layout300 {
  width: 100%;
  height: 1300px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-top: 0px;
  flex-direction: column;
  padding-bottom: 0px;
}
.commercial-cleaning-1-max-width {
  gap: var(--dl-space-space-threeunits);
  height: 1136px;
  align-items: center;
}
.commercial-cleaning-1-section-title {
  align-items: center;
}
.commercial-cleaning-1-text1 {
  text-align: center;
}
.commercial-cleaning-1-content {
  height: 966px;
  align-self: stretch;
  align-items: center;
}
.commercial-cleaning-1-feature1 {
  align-items: flex-start;
}
.commercial-cleaning-1-feature1-title {
  text-align: center;
}
.commercial-cleaning-1-feature2 {
  align-items: flex-start;
}
.commercial-cleaning-1-feature3 {
  align-items: flex-start;
}
.commercial-cleaning-1-feature4 {
  align-items: flex-start;
}
.commercial-cleaning-1-feature41 {
  align-items: flex-start;
}
.commercial-cleaning-1-feature42 {
  align-items: flex-start;
}
.commercial-cleaning-1-actions {
  align-items: flex-start;
}

@media(max-width: 991px) {
  .commercial-cleaning-1-section-title {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .commercial-cleaning-1-section-title {
    width: auto;
  }
  .commercial-cleaning-1-text {
    text-align: center;
  }
  .commercial-cleaning-1-feature2 {
    width: auto;
  }
  .commercial-cleaning-1-feature3 {
    width: auto;
  }
  .commercial-cleaning-1-feature4 {
    width: auto;
  }
  .commercial-cleaning-1-feature41 {
    width: auto;
  }
  .commercial-cleaning-1-feature42 {
    width: auto;
  }
}
@media(max-width: 479px) {
  .commercial-cleaning-1-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .commercial-cleaning-1-actions {
    width: 100%;
    flex-direction: column;
  }
}
