.about-hydrocare-header26 {
  gap: var(--dl-space-space-twounits);
}
.about-hydrocare-max-width {
  align-self: center;
}
.about-hydrocare-column {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.about-hydrocare-content {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.about-hydrocare-text {
  text-align: center;
}
.about-hydrocare-text1 {
  text-align: center;
}
.about-hydrocare-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
@media(max-width: 479px) {
  .about-hydrocare-actions {
    width: 100%;
    flex-direction: column;
  }
  .about-hydrocare-button {
    width: 100%;
  }
  .about-hydrocare-button1 {
    width: 100%;
  }
}
