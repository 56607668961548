.residential-cleaning-layout300 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.residential-cleaning-max-width {
  gap: var(--dl-space-space-threeunits);
  align-items: center;
}
.residential-cleaning-section-title {
  align-items: center;
}
.residential-cleaning-text {
  animation-name: slideOutUp;
  text-transform: uppercase;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.residential-cleaning-text1 {
  text-align: center;
}
.residential-cleaning-content {
  align-self: stretch;
  align-items: center;
}
.residential-cleaning-feature1 {
  align-items: flex-start;
}
.residential-cleaning-feature1-title {
  text-align: center;
}
.residential-cleaning-feature2 {
  align-items: flex-start;
}
.residential-cleaning-feature3 {
  align-items: flex-start;
}
.residential-cleaning-actions {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  align-items: flex-start;
}
@media(max-width: 991px) {
  .residential-cleaning-section-title {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .residential-cleaning-section-title {
    width: auto;
  }
  .residential-cleaning-text {
    text-align: center;
  }
  .residential-cleaning-feature2 {
    width: auto;
  }
  .residential-cleaning-feature3 {
    width: auto;
  }
}
@media(max-width: 479px) {
  .residential-cleaning-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .residential-cleaning-actions {
    width: 100%;
    flex-direction: column;
  }
}
