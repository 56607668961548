.about-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.about-footer1 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.about-max-width {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.about-content {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-radius4);
}
.about-links {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: flex-end;
}
.about-column1 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.about-footer-links {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.about-column2 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.about-footer-links1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.about-column3 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.about-social-links {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.about-link {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.about-social-link1 {
  text-decoration: none;
}
.about-link1 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.about-social-link2 {
  text-decoration: none;
}
.about-link2 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.about-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.about-link3 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
@media(max-width: 767px) {
  .about-content {
    flex-direction: column;
  }
  .about-links {
    width: 100%;
    align-items: flex-start;
    justify-content: center;
  }
  .about-column1 {
    align-items: center;
  }
  .about-column1-title {
    text-align: center;
  }
  .about-footer-links {
    align-self: center;
  }
  .about-column2 {
    align-items: center;
  }
  .about-column2-title {
    text-align: center;
  }
  .about-footer-links1 {
    align-self: center;
  }
  .about-column3 {
    align-items: center;
  }
  .about-social-link1-title {
    text-align: center;
  }
  .about-social-links {
    align-self: center;
  }
}
@media(max-width: 479px) {
  .about-links {
    flex-direction: column;
  }
  .about-column1 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .about-footer-links {
    align-items: center;
    justify-content: center;
  }
  .about-column2 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .about-footer-links1 {
    align-items: center;
    justify-content: center;
  }
  .about-column3 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .about-social-links {
    align-items: center;
    justify-content: center;
  }
}
