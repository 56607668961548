.meet-the-team-max-width {
  gap: var(--dl-space-space-fiveunits);
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.meet-the-team-section-title {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.meet-the-team-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.meet-the-team-text01 {
  align-self: flex-start;
}
.meet-the-team-text02 {
  align-self: flex-start;
}
.meet-the-team-content01 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fill,minmax(250px, 1fr));
}
.meet-the-team-card {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.meet-the-team-placeholder-image {
  width: 80px;
  height: 80px;
}
.meet-the-team-content02 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.meet-the-team-title {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.meet-the-team-text03 {
  font-style: normal;
  font-weight: 600;
}
.meet-the-team-text04 {
  text-align: center;
}
.meet-the-team-social-icons {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.meet-the-team-card1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.meet-the-team-placeholder-image1 {
  width: 80px;
  height: 80px;
}
.meet-the-team-content03 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.meet-the-team-title1 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.meet-the-team-text06 {
  font-style: normal;
  font-weight: 600;
}
.meet-the-team-text07 {
  text-align: center;
}
.meet-the-team-social-icons1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.meet-the-team-card2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.meet-the-team-placeholder-image2 {
  width: 80px;
  height: 80px;
}
.meet-the-team-content04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.meet-the-team-title2 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.meet-the-team-text09 {
  font-style: normal;
  font-weight: 600;
}
.meet-the-team-text10 {
  text-align: center;
}
.meet-the-team-social-icons2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.meet-the-team-card3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.meet-the-team-placeholder-image3 {
  width: 80px;
  height: 80px;
}
.meet-the-team-content05 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.meet-the-team-title3 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.meet-the-team-text12 {
  font-style: normal;
  font-weight: 600;
}
.meet-the-team-text13 {
  text-align: center;
}
.meet-the-team-social-icons3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.meet-the-team-card4 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.meet-the-team-placeholder-image4 {
  width: 80px;
  height: 80px;
}
.meet-the-team-content06 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.meet-the-team-title4 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.meet-the-team-text15 {
  font-style: normal;
  font-weight: 600;
}
.meet-the-team-text16 {
  text-align: center;
}
.meet-the-team-social-icons4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.meet-the-team-card5 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.meet-the-team-placeholder-image5 {
  width: 80px;
  height: 80px;
}
.meet-the-team-content07 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.meet-the-team-title5 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.meet-the-team-text18 {
  font-style: normal;
  font-weight: 600;
}
.meet-the-team-text19 {
  text-align: center;
}
.meet-the-team-social-icons5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.meet-the-team-content08 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
}
.meet-the-team-content09 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.meet-the-team-text21 {
  align-self: flex-start;
  font-stretch: normal;
}
.meet-the-team-text22 {
  align-self: flex-start;
}
.meet-the-team-button {
  width: 100%;
  max-width: 300px;
}
@media(max-width: 479px) {
  .meet-the-team-button {
    width: 100%;
  }
}
