.hydro-care-africa-steps-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.hydro-care-africa-steps-max-width {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.hydro-care-africa-steps-container1 {
  align-items: start;
}
.hydro-care-africa-steps-section-header {
  gap: var(--dl-space-space-oneandhalfunits);
  top: 10%;
  width: 511px;
  height: 402px;
  display: flex;
  position: sticky;
  align-items: flex-start;
  flex-direction: column;
}
.hydro-care-africa-steps-text01 {
  width: 502px;
  height: 395px;
}
.hydro-care-africa-steps-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.hydro-care-africa-steps-text02 {
  text-decoration: none;
}
.hydro-care-africa-steps-container2 {
  grid-area: span 1/span 1/span 1/span 1;
}
.hydro-care-africa-steps-container3 {
  top: 10%;
  position: sticky;
  transform: rotate(-2deg);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-theme-accent1);
}
.hydro-care-africa-steps-text04 {
  text-align: center;
}
.hydro-care-africa-steps-text05 {
  top: var(--dl-space-space-unit);
  right: var(--dl-space-space-unit);
  position: absolute;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}
.hydro-care-africa-steps-container4 {
  top: 10%;
  position: sticky;
  transform: rotate(2deg);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-theme-accent2);
}
.hydro-care-africa-steps-text07 {
  text-align: center;
}
.hydro-care-africa-steps-text08 {
  top: var(--dl-space-space-unit);
  right: var(--dl-space-space-unit);
  position: absolute;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}
.hydro-care-africa-steps-container5 {
  top: 10%;
  position: sticky;
  transform: rotate(-2deg);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-theme-accent1);
}
.hydro-care-africa-steps-text10 {
  text-align: center;
}
.hydro-care-africa-steps-text11 {
  top: var(--dl-space-space-unit);
  right: var(--dl-space-space-unit);
  position: absolute;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}
.hydro-care-africa-steps-container6 {
  top: 10%;
  position: sticky;
  transform: rotate(2deg);
  background-color: var(--dl-color-theme-accent2);
}
.hydro-care-africa-steps-text13 {
  text-align: center;
}
.hydro-care-africa-steps-text14 {
  top: var(--dl-space-space-unit);
  right: var(--dl-space-space-unit);
  position: absolute;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

@media(max-width: 991px) {
  .hydro-care-africa-steps-max-width {
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .hydro-care-africa-steps-section-header {
    position: static;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .hydro-care-africa-steps-actions {
    width: 100%;
    align-self: flex-start;
  }
  .hydro-care-africa-steps-container3 {
    width: 100%;
  }
  .hydro-care-africa-steps-container4 {
    width: 100%;
  }
  .hydro-care-africa-steps-container5 {
    width: 100%;
  }
  .hydro-care-africa-steps-container6 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .hydro-care-africa-steps-button {
    width: 100%;
  }
}
