.key-clients-container {
  position: relative;
}
.key-clients-max-width {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.key-clients-text {
  text-align: center;
}
.key-clients-logo1 {
  object-fit: contain;
}
.key-clients-logo2 {
  object-fit: contain;
}
.key-clients-logo3 {
  object-fit: contain;
}
.key-clients-logo4 {
  object-fit: contain;
}
.key-clients-logo5 {
  object-fit: contain;
}
.key-clients-logo6 {
  object-fit: contain;
}
.key-clients-logo7 {
  object-fit: contain;
}
.key-clients-logo8 {
  object-fit: contain;
}
.key-clients-logo9 {
  object-fit: contain;
}
.key-clients-logo10 {
  object-fit: contain;
}
