.footer-footer1 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.footer-max-width {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-content {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-radius4);
}
.footer-links {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: flex-end;
}
.footer-column1 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer-footer-links {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer-column2 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer-footer-links1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer-column3 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer-social-links {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer-link {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.footer-social-link1 {
  text-decoration: none;
}
.footer-link1 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.footer-social-link2 {
  text-decoration: none;
}
.footer-link2 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.footer-container {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer-link3 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.footer-icon6 {
  width: 24px;
  height: 24px;
}


@media(max-width: 767px) {
  .footer-content {
    flex-direction: column;
  }
  .footer-links {
    width: 100%;
    align-items: flex-start;
    justify-content: center;
  }
  .footer-column1 {
    align-items: center;
  }
  .footer-column1-title {
    text-align: center;
  }
  .footer-footer-links {
    align-self: center;
  }
  .footer-column2 {
    align-items: center;
  }
  .footer-column2-title {
    text-align: center;
  }
  .footer-footer-links1 {
    align-self: center;
  }
  .footer-column3 {
    align-items: center;
  }
  .footer-social-link1-title {
    text-align: center;
  }
  .footer-social-links {
    align-self: center;
  }
}
@media(max-width: 479px) {
  .footer-links {
    flex-direction: column;
  }
  .footer-column1 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .footer-footer-links {
    align-items: center;
    justify-content: center;
  }
  .footer-column2 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .footer-footer-links1 {
    align-items: center;
    justify-content: center;
  }
  .footer-column3 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .footer-social-links {
    align-items: center;
    justify-content: center;
  }
}
