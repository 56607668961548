.our-services-header-layout216 {
  width: 100%;
  height: 579px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-top: 0px;
  flex-direction: column;
  padding-bottom: 0px;
  justify-content: center;
}
.our-services-header-max-width {
  gap: var(--dl-space-space-threeunits);
  height: 100%;
  padding-top: 5px;
}
.our-services-header-section-title {
  align-items: flex-start;
}
.our-services-header-text1 {
  height: 174px;
}
.our-services-header-actions {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  align-self: flex-start;
}
@media(max-width: 991px) {
  .our-services-header-layout216 {
    width: 100%;
  }
  .our-services-header-max-width {
    flex-direction: column;
  }
  .our-services-header-image1 {
    width: 100%;
  }
  .our-services-header-content {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .our-services-header-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .our-services-header-actions {
    width: 100%;
    flex-wrap: wrap;
    margin-top: var(--dl-space-space-unit);
  }
}
