.commercial-cleaning-layout349 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.commercial-cleaning-max-width {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
}
.commercial-cleaning-content {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.commercial-cleaning-section-title {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.commercial-cleaning-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.commercial-cleaning-actions {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
}
.commercial-cleaning-image-container {
  flex: 1;
  display: flex;
  align-items: center;
}
.commercial-cleaning-placeholder-image {
  border-radius: var(--dl-radius-radius-radius4);
}
@media(max-width: 991px) {
  .commercial-cleaning-max-width {
    gap: var(--dl-space-space-twounits);
    flex-direction: column-reverse;
  }
}
@media(max-width: 479px) {
  .commercial-cleaning-actions {
    width: 100%;
    flex-direction: column;
  }
}
