.gallery-contact-us-gallery3 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: 381px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-top: 0px;
  flex-direction: column;
  padding-bottom: 0px;
}
.gallery-contact-us-max-width {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.gallery-contact-us-content {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.gallery-contact-us-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery-contact-us-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery-contact-us-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media(max-width: 991px) {
  .gallery-contact-us-content {
    align-items: center;
    flex-direction: column;
  }
}
