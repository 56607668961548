.timeline-max-width {
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.timeline-container01 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.timeline-text01 {
  text-align: left;
}
.timeline-container02 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.timeline-timeline-container {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.timeline-step1 {
  display: flex;
  position: relative;
  justify-content: center;
}
.timeline-container03 {
  flex: 1;
  left: var(--dl-space-space-halfunit);
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.timeline-container04 {
  width: 12px;
  height: 12px;
  margin: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  background-color: #000000;
}
.timeline-progress0 {
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #000000;
}
.timeline-container05 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.timeline-text03 {
  text-align: left;
}
.timeline-container06 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
}
.timeline-step2 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.timeline-container07 {
  flex: 1;
  left: var(--dl-space-space-halfunit);
  width: auto;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.timeline-container08 {
  width: 12px;
  height: 12px;
  margin: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  background-color: #000000;
}
.timeline-progress01 {
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #000000;
}
.timeline-container09 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.timeline-text06 {
  text-align: left;
}
.timeline-container10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
}
.timeline-step3 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.timeline-container11 {
  flex: 1;
  left: var(--dl-space-space-halfunit);
  width: auto;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.timeline-container12 {
  width: 12px;
  height: 12px;
  margin: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  background-color: #000000;
}
.timeline-progress02 {
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #000000;
}
.timeline-container13 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.timeline-text09 {
  text-align: left;
}
.timeline-container14 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
}
.timeline-step4 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.timeline-container15 {
  flex: 1;
  left: var(--dl-space-space-halfunit);
  width: auto;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.timeline-container16 {
  width: 12px;
  height: 12px;
  margin: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  background-color: #000000;
}
.timeline-progress03 {
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #000000;
}
.timeline-container17 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.timeline-text12 {
  text-align: left;
}
.timeline-container18 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
}
@media(max-width: 991px) {
  .timeline-max-width {
    gap: var(--dl-space-space-twounits);
  }
}
@media(max-width: 767px) {
  .timeline-max-width {
    flex-direction: column;
  }
  .timeline-text01 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .timeline-text03 {
    text-align: left;
  }
  .timeline-text06 {
    text-align: left;
  }
  .timeline-text09 {
    text-align: left;
  }
  .timeline-text12 {
    text-align: left;
  }
}
@media(max-width: 479px) {
  .timeline-container01 {
    gap: var(--dl-space-space-unit);
  }
  .timeline-container02 {
    width: 100%;
    align-items: stretch;
    flex-direction: column;
  }
  .timeline-timeline-container {
    width: 100%;
  }
  .timeline-container06 {
    width: 100%;
    align-items: stretch;
    flex-direction: column;
  }
  .timeline-container10 {
    width: 100%;
    align-items: stretch;
    flex-direction: column;
  }
  .timeline-container14 {
    width: 100%;
    align-items: stretch;
    flex-direction: column;
  }
  .timeline-container18 {
    width: 100%;
    align-items: stretch;
    flex-direction: column;
  }
}
