.navi-header-container {
  width: 100%;
  display: flex;
  z-index: 100;
  position: relative;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}
.navi-header-navbar-interactive {
  width: 100%;
  display: flex;
  z-index: 100;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.navi-header-image1 {
  height: 3rem;
  background-size: cover;
  background-image: url("https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/84ec08e8-34e9-42c7-9445-d2806d156403/fac575ac-7a41-484f-b7ac-875042de11f8?org_if_sml=1&force_format=original");
}
.navi-header-container1 {
  top: 100px;
  right: 0px;
  width: 35%;
  display: grid;
  padding: var(--dl-space-space-oneandhalfunits);
  grid-gap: var(--dl-space-space-halfunit);
  position: absolute;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  animation-name: fadeInDown;
  animation-delay: 0s;
  background-color: var(--dl-color-theme-neutral-light);
  animation-duration: 300ms;
  animation-direction: normal;
  grid-template-columns: 1fr;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.navi-header-menu-item {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
}
.navi-header-page1-image {
  width: 100px;
  height: 100px;
}
.navi-header-content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navi-header-page1 {
  font-style: normal;
  font-weight: 600;
}
.navi-header-menu-item1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
}
.navi-header-page2-image {
  width: 100px;
  height: 100px;
}
.navi-header-content1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navi-header-page2 {
  font-style: normal;
  font-weight: 600;
}
.navi-header-menu-item2 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
}
.navi-header-page3-image {
  width: 100px;
  height: 100px;
}
.navi-header-content2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navi-header-page3 {
  font-style: normal;
  font-weight: 600;
}
.navi-header-menu-item3 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
}
.navi-header-page4-image {
  width: 100px;
  height: 100px;
}
.navi-header-content3 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navi-header-page4 {
  font-style: normal;
  font-weight: 600;
}
.navi-header-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.navi-header-links {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navi-header-link5-dropdown-trigger {
  gap: 4px;
  cursor: pointer;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navi-header-icon-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navi-header-container2 {
  display: flex;
  align-items: center;
  animation-name: rotateInDownLeft;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: flex-end;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.navi-header-icon {
  width: 24px;
  height: 24px;
}
.navi-header-container3 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  animation-name: rotateInDownRight;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: flex-end;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.navi-header-burger-menu {
  display: none;
}
.navi-header-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navi-header-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-light);
}
.navi-header-nav {
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.navi-header-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navi-header-logo {
  height: 3rem;
}
.navi-header-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navi-header-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navi-header-links1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
}
.navi-header-link5-accordion {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navi-header-trigger {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.navi-header-icon-container1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navi-header-container4 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navi-header-icon06 {
  width: 24px;
  height: 24px;
}
.navi-header-container5 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navi-header-icon08 {
  width: 24px;
  height: 24px;
}
.navi-header-container6 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-halfunit);
  grid-template-columns: 1fr;
}
.navi-header-menu-item4 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navi-header-page1-image1 {
  width: 50px;
  height: 50px;
}
.navi-header-content4 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navi-header-page11 {
  font-style: normal;
  font-weight: 600;
}
.navi-header-menu-item5 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navi-header-page2-image1 {
  width: 50px;
  height: 50px;
}
.navi-header-content5 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navi-header-page21 {
  font-style: normal;
  font-weight: 600;
}
.navi-header-menu-item6 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navi-header-page3-image1 {
  width: 50px;
  height: 50px;
}
.navi-header-content6 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navi-header-page31 {
  font-style: normal;
  font-weight: 600;
}
.navi-header-menu-item7 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navi-header-page4-image1 {
  width: 50px;
  height: 50px;
}
.navi-header-content7 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navi-header-page41 {
  font-style: normal;
  font-weight: 600;
}
.navi-header-icon-group {
  gap: var(--dl-space-space-twounits);
  display: flex;
}
.navi-header-icon10 {
  height: var(--dl-size-size-xsmall);
}
.navi-header-icon12 {
  height: var(--dl-size-size-xsmall);
}
.navi-header-icon14 {
  height: var(--dl-size-size-xsmall);
}
.navi-header-container7 {
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  align-items: flex-start;
  flex-direction: column;
}




@media(max-width: 991px) {
  .navi-header-container1 {
    width: 50%;
  }
}
@media(max-width: 767px) {
  .navi-header-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navi-header-container1 {
    display: none;
  }
  .navi-header-desktop-menu {
    display: none;
  }
  .navi-header-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .navi-header-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .navi-header-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}
