.front-galary-header78 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.front-galary-column {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.front-galary-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.front-galary-text {
  text-align: center;
}
.front-galary-text1 {
  text-align: center;
}
.front-galary-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.front-galary-row-container {
  width: 100%;
}
.front-galary-placeholder-image {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image01 {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image02 {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image03 {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image04 {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image05 {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image06 {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image07 {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image08 {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image09 {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image10 {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image11 {
  width: 400px;
  height: 400px;
}
.front-galary-row-container1 {
  width: 100%;
}
.front-galary-placeholder-image12 {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image13 {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image14 {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image15 {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image16 {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image17 {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image18 {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image19 {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image20 {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image21 {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image22 {
  width: 400px;
  height: 400px;
}
.front-galary-placeholder-image23 {
  width: 400px;
  height: 400px;
}
.front-galary-container1 {
  display: contents;
}

@media(max-width: 767px) {
  .front-galary-content1 {
    width: 100%;
  }
}
